<template lang="pug">

	.architects

		section.architects__section.architects__section--main

			.architects__container(class="container")

				.architects__content

					img.architects__img.architects__img--main(src="~@/assets/img/architects/CADdetails.jpeg")
					h1.architects__title(class="primary-title") CADdetails

					p.architects__sub-title(class="sub-title") Our Design Files are Live! Download high-quality CAD drawings, specifications, and related documents for your next project. The CADdetails program is developed specifically for design professionals with the goal of getting manufacturer-specific product information into their working plans.
					a.architects__link(class="link link__cad" href="http://microsite.caddetails.com/5512" target="_blank") View CADdetails


			.products__container(class="container")


</template>

<script>
export default {
  name: 'ArchitectsView',

  data() {
    return {

    }
  }
}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.architects


  &__section


    &--main
      min-height: 67vh
      position: relative


      @media screen and (max-width: $phone)
        min-height: 51rem


    &--contact
      position: relative
      margin: 20rem 0 15rem


      @media screen and (max-width: $phone)
        margin-top: 0


  &__container


    &--contact


      @media screen and (max-width: $phone)
        display: flex
        align-items: center
        justify-content: center


    &--contact
      object-fit: contain
      object-position: right

      position: absolute

      top: -5rem
      right: 0

      height: 120%
      max-width: 50%

      z-index: -1


      @media screen and (max-width: $phone)
        top: -10rem
        max-width: 99%


  &__content
    position: absolute

    top: 29vh


    @media screen and (max-width: $desktop)
      top: 25vh


    @media screen and (max-width: $phone)
      width: 89%
      top: 29rem

      text-align: center

  &__img

    &--main
      object-fit: contain
      object-position: top

      top: 0
      left: 0


      z-index: -1

      float: left


      @media screen and (max-width: $phone)
        max-height: 31rem
        max-width: 101%

  &__title
    width: 100%
    margin-bottom: 1.8rem


    @media screen and (max-width: $phone)
      width: 99%


  &__sub-title


    @media screen and (max-width: $desktop)
      max-width: 92.9rem


    @media screen and (max-width: $phone)
      width: 100%

  &__link

    border-bottom: 3px solid #D72028
    padding: 0 0 4px
    color: #333336
    font-weight: bold
    margin-top: 10px

    @media screen and (max-width: $phone)
      font-size: $default_font_size__mobile


    &:not(:last-child)
      margin-right: 4.7rem


      @media screen and (max-width: $phone)
        margin-bottom: 3rem

</style>
